.mainDiv {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow flex items to wrap to the next line */
  background-color: beige;
  margin-top: 5%;
}

.Div1,
.Div3 {
  width: 300px;
  height: 700px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .mainDiv {
    flex-direction: row; /* Align items in a row on small screens */
    margin-top: 20%;
  }
}
