/* AboutUsPage.css */

.about-us-container {
  padding: 50px;
  background-color: #f9f9f9;
}

.about-us-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.about-us-content h1 {
  font-size: 2.5em;
  color: #333;
}

.about-us-content h2 {
  font-size: 2em;
  color: #555;
  margin-top: 30px;
}

.about-us-content p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666;
}

.about-us-content ul {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.about-us-content ul li {
  font-size: 1.2em;
  color: #777;
  margin-bottom: 10px;
}

.about-us-content ul li::before {
  content: "•";
  margin-right: 10px;
  color: #999;
}
