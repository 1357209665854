/* HeaderImage.css */

.header-image-container {
  position: relative;
}

.header-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-image {
  width: 100%;
  max-height: 500px;
  opacity: 0.6;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 24px;
  animation: fadeIn 2s ease, slideIn 2s ease; /* Add animation for fading in and sliding in */
}

.header-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.header-button:hover {
  background-color: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .header-text {
    font-size: 18px; /* Decrease font size for smaller screens */
  }

  .header-button {
    margin-top: 15px; /* Adjust button margin for smaller screens */
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translate(-50%, -70%); /* Slide in from top */
  }
  to {
    transform: translate(-50%, -50%); /* Slide in to center */
  }
}
